<style scoped>
@media (max-width: 1024px) {
  .c-sm {
    margin-top: 1rem;
    border-right: unset !important;
  }
  .c-sm-border {
    padding-top: 1rem;
    border-top: 1px solid #32394e !important;
  }
}
.c-bottom {
  position: absolute;
  right:    0;
  bottom:   0;
  margin-right: 12px;
}
.c-features {
  margin-top: 1rem;
  margin-left: 2rem;
  font-size: 1rem;
  color: white;
  text-align: left;
}
.c-features-horizontal {
	margin-top: 1rem;
	font-size: 1rem;
	color: white;
	text-align: left;
}
.c-feature-description {

}
.c-plan-badge {
  margin-top: -35px;
  margin-bottom: 9px;
  z-index: 1;
  cursor: pointer;
}
.c-plan-container {
  margin-top: -25px;
}
.c-subscription-container {
  width: 500px;
  height: 300px;
}
.pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #f1b44c;
  }

  100% {
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
  }
}
.pulse-danger {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse-danger 2s infinite;
}
@keyframes pulse-danger {
  0% {
    box-shadow: 0 0 0 0 #f46a6a;
  }

  100% {
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
  }
}
.plan-fade-pro {
	background: -webkit-linear-gradient(#E7F7F3, #A0F4E7);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.plan-fade-community {
	background: -webkit-linear-gradient(#E4E7E9, #7F6B8D);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.plan-fade-advanced {
	background: -webkit-linear-gradient(#808080, #696969);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
</style>
<script>

import {get_acsrf_token} from "@/methods";

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import CopyableText from "@/components/CopyableText";
import {HalfCircleSpinner} from "epic-spinners";
import {mapGetters} from "vuex";
import {SubscriptionStatus, Game} from "@/enums";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    HalfCircleSpinner,
    Checkout: () => import('@/components/lazy/billing/Checkout'),
  },
  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId',
      'getDTLocale'
    ])
  },
  methods: {
    async getSubscriptionStatus() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/store/subscription/status?family=omega`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          if(data.subscription) {
            this.hasSubscription = true;
            this.subscription = data.subscription;
          }
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async getAvailablePlans() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/store/subscription/plans?family=omega`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.plans = data.plans;
          this.flags = data.flags;
          this.capabilities = data.capabilities;
          this.changeInfo = data.plan_change;
	        this.discounts = data.discounts;
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async processPlanSelection(selectedPlan) {
      if(this.hasSubscription && this.subscription.code === selectedPlan) return;
      this.checkoutPlan = selectedPlan;
      this.showCheckout = true;
    },
    async doHideCheckout() {
      this.ready = false;
      this.showCheckout = false;
      await this.getAvailablePlans();
      if(!this.error) {
        this.ready = true;
        this.$nextTick(async () => {
          await this.getSubscriptionStatus();
        });
      }
    },
    async cancelPlanChange() {
      this.processingCancellation = true;
      let result = await this.$swal({
        icon: 'warning',
        title: this.$t('billing.subscription.active.change.cancel.title'),
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if(!result.isConfirmed) {
        this.processingCancellation = false;
        return;
      }

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          family: 'omega'
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/store/subscription/cancel?family=omega`, {
          method: 'PUT',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(!response.ok) {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
        this.processingCancellation = false;
      }
    },
    async cancelSubscription() {
      this.processingCancellation = true;

      let result = await this.$swal({
        icon: 'warning',
        text: this.$t('billing.subscription.active.cancel.message'),
        title: this.$t('billing.subscription.active.cancel.title'),
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if(!result.isConfirmed) {
        this.processingCancellation = false;
        return;
      }

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          family: 'omega'
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/store/subscription/cancel?family=omega`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(!response.ok) {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
        this.processingCancellation = false;
      }
    },
    async withdrawCancelationSubscription() {
      this.processingCancellation = true;

      let result = await this.$swal({
        icon: 'warning',
        text: this.$t('billing.subscription.active.withdraw_cancel.message'),
        title: this.$t('billing.subscription.active.withdraw_cancel.title'),
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if(!result.isConfirmed) {
        this.processingCancellation = false;
        return;
      }

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          family: 'omega'
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/store/subscription/cancel?family=omega`, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(!response.ok) {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
        this.processingCancellation = false;
      }
    },
    async handleSubscriptionStatusUpdate(update) {
      if(update.subscription_id !== this.subscription.id) return;
      this.processingCancellation = false;
      if(update.status === SubscriptionStatus.ACTIVE) {
	      if(update.error) {
		      this.$swal({
			      icon: 'warning',
			      text: this.$t('billing.subscription.active.withdraw_cancel.error')
		      });
	      } else {
		      this.$swal({
			      icon: 'success',
			      text: this.$t('billing.subscription.active.withdraw_cancel.success')
		      });
	      }
      } else if(update.status === SubscriptionStatus.CANCELLED) {
				if(update.error) {
					this.$swal({
						icon: 'warning',
						text: this.$t('billing.subscription.active.cancel.error')
					});
				} else {
					this.$swal({
						icon: 'warning',
						text: this.$t('billing.subscription.active.cancel.success')
					});
				}
      }
      await this.getSubscriptionStatus();
    },
    async handleSubscriptionUpdate() {
      this.processingCancellation = false;
      await this.getSubscriptionStatus();
      await this.getAvailablePlans();
      this.$swal({
        icon: 'success',
        text: this.$t('billing.subscription.active.change.cancel.success')
      });
    }
  },
  created() {
    try {
      this.$socket.client.on('billing:subscription:status', this.handleSubscriptionStatusUpdate);
      this.$socket.client.on('billing:subscription:update', this.handleSubscriptionUpdate);
    } catch (e) {
      // nothing
    }
  },
  async mounted() {
    await this.getAvailablePlans();
    if(!this.error) {
      this.ready = true;
      this.$nextTick(async () => {
        await this.getSubscriptionStatus();
      });
    }
  },
  destroyed() {
    try {
      this.$socket.client.off('billing:subscription:status', this.handleSubscriptionStatusUpdate);
      this.$socket.client.off('billing:subscription:update', this.handleSubscriptionUpdate);
    } catch (e) {
      // nothing
    }
  },
  data() {
    return {
      Game: Game,
      SubscriptionStatus:SubscriptionStatus,
      ready: false,
      error: false,
      processingCancellation: false,
      hasSubscription: false,
      subscription: {},
      plans: {},
      capabilities: {},
      changeInfo: {},
	    discounts: null,
      flags: {},
      // Checkout
      showCheckout: false,
      checkoutPlan: null
    }
  }
};
</script>

<template>
  <div class="c-plan-container mt-1">
    <template v-if="ready">
      <template v-if="showCheckout">
        <Checkout :plan="checkoutPlan" @hideCheckout="doHideCheckout()" @cancelCheckout="showCheckout = false;" />
      </template>
      <template v-else>
        <div class="row" v-if="hasSubscription">
          <div class="col-lg-12 col-sm-12">
            <div class="card card-body border" :class="{'border-primary shadow shadow-lg': subscription.status === SubscriptionStatus.ACTIVE, 'border-warning pulse': subscription.status === SubscriptionStatus.CANCELLED}">
              <h4>
	              CFTools Cloud
	              <template v-if="subscription.plan.code === 'pro'">
		              <h3 class="d-inline-block m-0 p-0 plan-fade-pro">{{ subscription.plan.name }}</h3>
	              </template>
	              <template v-else-if="subscription.plan.code === 'community'">
		              <h3 class="d-inline-block m-0 p-0 plan-fade-community">{{ subscription.plan.name }}</h3>
	              </template>
	              <template v-else-if="subscription.plan.code === 'advanced'">
		              <h3 class="d-inline-block m-0 p-0 plan-fade-advanced ">{{ subscription.plan.name }}</h3>
	              </template>
	              <template v-else>
		              <b>{{ subscription.plan.name }}</b>
	              </template>
              </h4>
              <div class="row">
                <div class="col-lg-4 col-sm-12">
                  <div>
                    <h6 class="mb-0 text-muted">
                      {{$t('billing.subscription.active.number')}}
                    </h6>
                    <h5>
                      {{ subscription.number }} <small class="text-muted">{{$t('billing.subscription.active.duration', {days: subscription.age})}}</small>
                    </h5>
                  </div>
                  <div>
                    <h6 class="mb-0 text-muted">
                      {{$t('billing.subscription.active.status')}}
                    </h6>
                    <template v-if="subscription.status === SubscriptionStatus.ACTIVE">
                      <h5 class="text-primary">
                      {{$t('billing.subscription.active.states.active', {date: $d(parseDate(subscription.created_at), 'date', getDTLocale())})}}
                      </h5>
                    </template>
                    <template v-else-if="subscription.status === SubscriptionStatus.CANCELLED">
                      <h5 class="text-warning">
                      {{$t('billing.subscription.active.states.cancelled', {date: $d(parseDate(subscription.expires_at), 'date', getDTLocale())})}}
                      </h5>
                    </template>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div>
                    <h6 class="mb-0 text-muted font-size-12">
                      {{$t('billing.subscription.active.next_bill')}}
                    </h6>
                    <h5>
                      {{ $d(parseDate(subscription.expires_at), 'date', getDTLocale()) }}
                    </h5>
                  </div>
                  <div>
                    <h6 class="mb-0 text-muted">
                      {{$t('billing.subscription.active.cost')}}
                    </h6>
                    <h5>
                      {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(subscription.plan.cost) }}
                      <small class="text-muted">
                        / month
                      </small>
                      <template v-if="subscription.plan_change.in_progress && subscription.plan_change.cost">
                        +
                        {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(subscription.plan_change.cost) }}
                        <small class="text-muted">
                          / next billing period (Plan change)
                        </small>
                      </template>
                    </h5>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div v-if="subscription.plan_change.in_progress">
                    <h6 class="mb-0 text-muted font-size-12">
                      {{$t('billing.subscription.active.change.title')}}
                    </h6>
                    <h5>
                      <template v-if="subscription.plan_change.processed">
                        {{$t('billing.subscription.active.change.processed')}}
                      </template>
                      <template v-else>
                        {{$t('billing.subscription.active.change.in_progress', {plan: subscription.plan_change.name})}}
                      </template>
                    </h5>
                  </div>
                  <template v-if="subscription.plan_change.in_progress && subscription.plan_change.cancellable">
                    <button class="btn btn-outline-warning bottom-left mt-2" :class="{'disabled': processingCancellation}" :disabled="processingCancellation" v-on:click="cancelPlanChange()">
                      <half-circle-spinner
                          v-if="processingCancellation"
                          :animation-duration="900"
                          :size="18"
                          class="align-middle d-inline-block"
                      />
                      {{$t('billing.subscription.active.change.cancel.button')}}
                    </button>
                  </template>
                  <template v-if="subscription.status === SubscriptionStatus.ACTIVE">
                    <button class="btn btn-outline-danger bottom-right c-bottom" :class="{'disabled': processingCancellation}" :disabled="processingCancellation" v-on:click="cancelSubscription()">
                      <half-circle-spinner
                          v-if="processingCancellation"
                          :animation-duration="900"
                          :size="18"
                          class="align-middle d-inline-block"
                      />
                      {{$t('billing.subscription.active.cancel.button')}}
                    </button>
                  </template>
                  <template v-else-if="subscription.status === SubscriptionStatus.CANCELLED">
                    <button class="btn btn-outline-info bottom-right c-bottom" :class="{'disabled': processingCancellation}" :disabled="processingCancellation" v-on:click="withdrawCancelationSubscription()">
                      <half-circle-spinner
                          v-if="processingCancellation"
                          :animation-duration="900"
                          :size="18"
                          class="align-middle d-inline-block"
                      />
                      {{$t('billing.subscription.active.withdraw_cancel.button')}}
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="flags.is_embargo">
          <div class="row">
            <div class="col-lg-12 col-sm-12">
              <div class="card card-body border border-danger pulse-danger text-center">
                <h3 class="text-danger">{{$t('billing.checkout.errors.embargo.title')}}</h3>
                <h5>{{$t('billing.checkout.errors.embargo.message')}}</h5>
              </div>
            </div>
          </div>
        </template>

	      <template v-if="!flags.allow_free">
		      <div class="row">
			      <div class="col-lg-12 col-sm-12">
				      <div class="card card-body border border-warning text-center">
					      <h3 class="text-warning">{{$t('billing.checkout.errors.free_restriction.title')}}</h3>
					      <h5>{{$t('billing.checkout.errors.free_restriction.message')}}</h5>
				      </div>
			      </div>
		      </div>
	      </template>

        <div class="row">
          <div class="col-lg-12 col-sm-12 h-100">
            <div class="">
	            <!-- START OF MAIN PLANS -->
              <div class="row justify-content-center text-center">
                <div class="col-lg-3 col-sm-12 c-sm" v-if="plans.free">
                  <div class="card card-body shadow shadow-lg h-100" :class="{'border border-success': hasSubscription && subscription.code === 'free'}">
                    <h3 class="mb-1">
                      {{$t('billing.subscription.plans.free.title')}}
                    </h3>
                    <h1 class="mb-1">
                      {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(plans.free) }}
                    </h1>
                    <h5 class="text-muted">
                      {{$t('billing.subscription.plans.period.monthly')}}
                    </h5>

                    <template v-if="hasSubscription && subscription.code === 'free'">
	                    <button class="btn btn-outline-success btn-lg mt-2 mb-2">
		                    <h5 class="mb-0 text-uppercase">
			                    {{$t('billing.subscription.plans.current')}}
		                    </h5>
	                    </button>
                    </template>
                    <template v-else-if="subscription.status !== SubscriptionStatus.CANCELLED">
                      <button class="btn btn-lg text-uppercase mt-2 mb-2" :class="{'btn-primary': !hasSubscription, 'btn-info': hasSubscription}" v-on:click="processPlanSelection('free')" v-if="!hasSubscription || hasSubscription && changeInfo.available">
                        <template v-if="hasSubscription">
                          <h5 class="mb-0">
                            {{$t('billing.subscription.plans.change')}}
                          </h5>
                        </template>
                        <template v-else>
                          <h5 class="mb-0">
                            {{$t('billing.subscription.plans.activate')}}
                          </h5>
                        </template>
                      </button>
                    </template>

                    <div class="c-features mb-auto">
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fas fa-server"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.free.servers === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.servers')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.free.servers === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_servers')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.free.servers) }} {{$t('billing.subscription.plans.descriptors.servers')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-ban"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.free.banlists === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.banlists')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.free.banlists === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_banlists')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.free.banlists) }} {{$t('billing.subscription.plans.descriptors.banlists')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fad fa-users"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.free.teams === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.teams')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.free.teams === 0">
                              <span class="text-muted">
                                {{$t('billing.subscription.plans.descriptors.limit_teams')}}
                              </span>
                            </template>
                            <template v-else>
                              {{ $n(capabilities.free.teams) }} {{$t('billing.subscription.plans.descriptors.teams')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <!-- Feature Checks -->
                      <div class="mt-3"/>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8 ">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.rcon')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-muted"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.scheduler')}}
                          </span>
                          <span class="small text-muted c-feature-description">
                            {{ $n(capabilities.free.jobs) }} {{$t('billing.subscription.plans.descriptors.limit_jobs')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-muted"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.messenger')}}
                          </span>
                          <span class="small text-muted c-feature-description">
                            {{ $n(capabilities.free.messages) }} {{$t('billing.subscription.plans.descriptors.limit_messages')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-muted"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.triggers')}}
                          </span>
                          <span class="small text-muted c-feature-description">
                            {{ $n(capabilities.free.triggers) }} {{$t('billing.subscription.plans.descriptors.limit_triggers')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-muted"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.integrations')}}
                          </span>
                          <span class="small text-muted c-feature-description">
                            {{ $n(capabilities.free.webhooks) }} {{$t('billing.subscription.plans.descriptors.limit_webhooks')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-times text-danger font-size-20"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description text-muted">
                            {{$t('billing.subscription.plans.features.hosting')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-times text-danger font-size-20"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description text-muted">
                            {{$t('billing.subscription.plans.features.support')}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-12 c-sm" v-if="plans.basic">
                  <div class="card card-body shadow shadow-lg h-100" :class="{'border border-success': hasSubscription && subscription.code === 'basic'}">
                    <h3 class="mb-1">
                      {{$t('billing.subscription.plans.basic.title')}}
                    </h3>
                    <h1 class="mb-1">
                      {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(plans.basic) }}
                    </h1>
                    <h5 class="text-muted">
                      {{$t('billing.subscription.plans.period.monthly')}}
                    </h5>

                    <template v-if="hasSubscription && subscription.code === 'basic'">
	                    <button class="btn btn-outline-success btn-lg mt-2 mb-2">
		                    <h5 class="mb-0 text-uppercase">
			                    {{$t('billing.subscription.plans.current')}}
		                    </h5>
	                    </button>
                    </template>
                    <template v-else-if="subscription.status !== SubscriptionStatus.CANCELLED">
                      <button class="btn btn-lg text-uppercase mt-2 mb-2" :class="{'btn-primary': !hasSubscription, 'btn-info': hasSubscription}" v-on:click="processPlanSelection('basic')" v-if="!hasSubscription || hasSubscription && changeInfo.available">
                        <template v-if="hasSubscription">
                          <h5 class="mb-0">
                            {{$t('billing.subscription.plans.change')}}
                          </h5>
                        </template>
                        <template v-else>
                          <h5 class="mb-0">
                            {{$t('billing.subscription.plans.subscribe')}}
                          </h5>
                        </template>
                      </button>
                    </template>

                    <div class="c-features mb-auto">
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fas fa-server"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.basic.servers === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.servers')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.basic.servers === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_servers')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.basic.servers) }} {{$t('billing.subscription.plans.descriptors.servers')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-ban"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.basic.banlists === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.banlists')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.basic.banlists === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_banlists')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.basic.banlists) }} {{$t('billing.subscription.plans.descriptors.banlists')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fad fa-users"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.basic.teams === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.teams')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.basic.teams === 0">
                              <span class="text-muted">
                                {{$t('billing.subscription.plans.descriptors.limit_teams')}}
                              </span>
                            </template>
                            <template v-else>
                              {{ $n(capabilities.basic.teams) }} {{$t('billing.subscription.plans.descriptors.teams')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <!-- Feature Checks -->
                      <div class="mt-3"/>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8 ">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.rcon')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.base')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description font-size-14">
                            {{$t('billing.subscription.plans.features.basic_combi')}}
                          </span>
                          <span class="small c-feature-description text-muted">
                            {{$t('billing.subscription.plans.descriptors.unlimited')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.integrations')}}
                          </span>
                          <span class="small c-feature-description text-muted">
                            {{$t('billing.subscription.plans.descriptors.fairuse')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.hosting')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.support')}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-12 c-sm" v-if="plans.pro">
                  <div class="card card-body shadow shadow-lg h-100" :class="{'border border-primary': !hasSubscription || subscription.code !== 'pro', 'border border-success': hasSubscription && subscription.code === 'pro'}">
                    <div class="text-center c-plan-badge float-none" :class="{'d-none': hasSubscription && subscription.code === 'pro'}">
                      <span class="badge badge-primary">
                        <h5 class="mb-0">
                          {{$t('billing.subscription.plans.descriptors.popular')}}
                        </h5>
                      </span>
                    </div>
                    <h3 class="mb-1">
                      {{$t('billing.subscription.plans.pro.title')}}
                    </h3>
                    <h1 class="mb-1">
                      {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(plans.pro) }}
                    </h1>
                    <h5 class="text-muted">
                      {{$t('billing.subscription.plans.period.monthly')}}
                    </h5>

                    <template v-if="hasSubscription && subscription.code === 'pro'">
                      <button class="btn btn-outline-success btn-lg mt-2 mb-2">
                        <h5 class="mb-0 text-uppercase">
                          {{$t('billing.subscription.plans.current')}}
                        </h5>
                      </button>
                    </template>
                    <template v-else-if="subscription.status !== SubscriptionStatus.CANCELLED">
                      <button class="btn btn-lg text-uppercase mt-2 mb-2" :class="{'btn-primary': !hasSubscription, 'btn-info': hasSubscription}" v-on:click="processPlanSelection('pro')" v-if="!hasSubscription || hasSubscription && changeInfo.available">
                        <template v-if="hasSubscription">
                          <h5 class="mb-0">
                            {{$t('billing.subscription.plans.change')}}
                          </h5>
                        </template>
                        <template v-else>
                          <h5 class="mb-0">
                            {{$t('billing.subscription.plans.subscribe')}}
                          </h5>
                        </template>
                      </button>
                    </template>

                    <div class="c-features mb-auto">
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fas fa-server"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.pro.servers === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.servers')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.pro.servers === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_servers')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.pro.servers) }} {{$t('billing.subscription.plans.descriptors.servers')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-ban"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.pro.banlists === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.banlists')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.pro.banlists === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_banlists')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.pro.banlists) }} {{$t('billing.subscription.plans.descriptors.banlists')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fad fa-users"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.pro.teams === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.teams')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.pro.teams === 0">
                              <span class="text-muted">
                                {{$t('billing.subscription.plans.descriptors.limit_teams')}}
                              </span>
                            </template>
                            <template v-else>
                              {{ $n(capabilities.pro.teams) }} {{$t('billing.subscription.plans.descriptors.teams')}}
                            </template>
                          </span>
                        </div>
                      </div>

                      <!-- Feature Checks -->
                      <div class="mt-3"/>

                      <div class="row">
                        <div class="col-10 text-left ">
                          <span class="badge badge-info c-feature-description text-black w-100">
                            {{$t('billing.subscription.plans.descriptors.carryover_basic')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.dedicated')}}
                          </span>
                        </div>
                      </div>
                      <!--
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.triggers_advanced')}}
                          </span>
                        </div>
                      </div>
                      -->
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.logs')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.privacy')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.watchers')}}
                          </span>
                        </div>
                      </div>

	                    <div class="row">
		                    <div class="col-2 text-center">
			                    <i class="far fa-check text-success"/>
		                    </div>
		                    <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.radar')}}
	                          <span class="badge badge-primary small">
		                          {{$t('billing.subscription.plans.features.soon')}}
	                          </span>
                          </span>
		                    </div>
	                    </div>

                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 c-sm" v-if="plans.community">
                  <div class="card card-body shadow shadow-lg h-100" :class="{'border border-success': hasSubscription && subscription.code === 'community'}">
                    <h3 class="mb-1">
                      {{$t('billing.subscription.plans.community.title')}}
                    </h3>
                    <h1 class="mb-1">
                      {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(plans.community) }}
                    </h1>
                    <h5 class="text-muted">
                      {{$t('billing.subscription.plans.period.monthly')}}
                    </h5>

                    <template v-if="hasSubscription && subscription.code === 'community'">
	                    <button class="btn btn-outline-success btn-lg mt-2 mb-2">
		                    <h5 class="mb-0 text-uppercase">
			                    {{$t('billing.subscription.plans.current')}}
		                    </h5>
	                    </button>
                    </template>
                    <template v-else-if="subscription.status !== SubscriptionStatus.CANCELLED">
                      <button class="btn btn-lg text-uppercase mt-2 mb-2" :class="{'btn-primary': !hasSubscription, 'btn-info': hasSubscription}" v-on:click="processPlanSelection('community')" v-if="!hasSubscription || hasSubscription && changeInfo.available">
                        <template v-if="hasSubscription">
                          <h5 class="mb-0">
                            {{$t('billing.subscription.plans.change')}}
                          </h5>
                        </template>
                        <template v-else>
                          <h5 class="mb-0">
                            {{$t('billing.subscription.plans.subscribe')}}
                          </h5>
                        </template>
                      </button>
                    </template>

                    <div class="c-features mb-auto">
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fas fa-server"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.community.servers === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.servers')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.community.servers === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_servers')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.community.servers) }} {{$t('billing.subscription.plans.descriptors.servers')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-ban"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.community.banlists === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.banlists')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.community.banlists === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_banlists')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.community.banlists) }} {{$t('billing.subscription.plans.descriptors.banlists')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fad fa-users"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.community.teams === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.teams')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.community.teams === 0">
                              <span class="text-muted">
                                {{$t('billing.subscription.plans.descriptors.limit_teams')}}
                              </span>
                            </template>
                            <template v-else>
                              {{ $n(capabilities.community.teams) }} {{$t('billing.subscription.plans.descriptors.teams')}}
                            </template>
                          </span>
                        </div>
                      </div>

                      <!-- Feature Checks -->
                      <div class="mt-3"/>

                      <div class="row">
                        <div class="col-10 text-left ">
                          <span class="badge badge-info c-feature-description text-black w-100">
                            {{$t('billing.subscription.plans.descriptors.carryover_pro')}}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description font-size-14">
                            {{$t('billing.subscription.plans.features.support_priority')}}
                          </span>
                        </div>
                      </div>
	                    <div class="row">
		                    <div class="col-2 text-center">
			                    <i class="far fa-check text-success"/>
		                    </div>
		                    <div class="col-8">
                          <span class="c-feature-description font-size-14">
                            {{$t('billing.subscription.plans.features.early_access')}}
                          </span>
		                    </div>
	                    </div>
	                    <div class="row">
		                    <div class="col-2 text-center">
			                    <i class="far fa-check text-success"/>
		                    </div>
		                    <div class="col-8">
                          <span class="c-feature-description font-size-14">
                            {{$t('billing.subscription.plans.features.global_player_intelligence')}}
                          </span>
		                    </div>
	                    </div>
	                    <div class="row">
		                    <div class="col-2 text-center">
			                    <i class="far fa-check text-success"/>
		                    </div>
		                    <div class="col-8">
                          <span class="c-feature-description font-size-14">
                            {{$t('billing.subscription.plans.features.advanced_radar')}}
	                          <span class="badge badge-primary small">
		                          {{$t('billing.subscription.plans.features.soon')}}
	                          </span>
                          </span>
		                    </div>
	                    </div>
	                    <div class="row">
		                    <div class="col-2 text-center">
			                    <i class="far fa-check text-success"/>
		                    </div>
		                    <div class="col-8">
                          <span class="c-feature-description font-size-14">
                            {{$t('billing.subscription.plans.features.watchers_teams')}}
	                          <span class="badge badge-primary small">
		                          {{$t('billing.subscription.plans.features.soon')}}
	                          </span>
                          </span>
		                    </div>
	                    </div>
                    </div>
                  </div>
                </div>
              </div>
	            <!-- END OF MAIN PLANS -->

	            <div class="row mt-3" v-if="plans.advanced">
		            <div class="col">
			            <div class="card card-body shadow shadow-lg h-100" :class="{'border border-success': hasSubscription && subscription.code === 'advanced'}">
				            <div class="row">
					            <div class="col-lg-3 col-sm-12 c-sm">
						            <h3 class="mb-1">
							            {{$t('billing.subscription.plans.advanced.title')}}
							            <template v-if="capabilities.advanced.preorder">
								            <span class="badge badge-warning text-black">
									            <i class="fad fa-info-circle"/>
									            Pre-Order
								            </span>
							            </template>
						            </h3>
						            <h1 class="mb-1">
							            <template v-if="capabilities.advanced.rebate_from">
								            {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(plans.advanced) }}
								            <small class="text-muted" style="text-decoration: line-through;">
								              {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(capabilities.advanced.rebate_from) }}
								            </small>
							            </template>
							            <template v-else>
								            {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(plans.advanced) }}
							            </template>
						            </h1>
						            <h5 class="text-muted">
							            {{$t('billing.subscription.plans.period.monthly')}}
						            </h5>

						            <template v-if="hasSubscription && subscription.code === 'advanced'">
							            <button class="btn btn-outline-success btn-lg mt-2 mb-2">
								            <h5 class="mb-0 text-uppercase">
									            {{$t('billing.subscription.plans.current')}}
								            </h5>
							            </button>
						            </template>
						            <template v-else-if="subscription.status !== SubscriptionStatus.CANCELLED">
							            <button class="btn btn-lg text-uppercase mt-2 mb-2" :class="{'btn-primary': !hasSubscription, 'btn-info': hasSubscription}" v-on:click="processPlanSelection('advanced')" v-if="!hasSubscription || hasSubscription && changeInfo.available">
								            <template v-if="hasSubscription">
									            <h5 class="mb-0">
										            {{$t('billing.subscription.plans.change')}}
									            </h5>
								            </template>
								            <template v-else>
									            <h5 class="mb-0">
										            {{$t('billing.subscription.plans.subscribe')}}
									            </h5>
								            </template>
							            </button>
						            </template>
					            </div>
					            <div class="col-lg-4 col-sm-12 c-sm">
						            <div class="c-features mb-auto">
							            <div class="row">
								            <div class="col-2 text-center">
									            <i class="fad fa-building"/>
								            </div>
								            <div class="col-8">
		                          <span class="c-feature-description">
		                            {{$t('billing.subscription.plans.descriptors.organizations')}}
			                          <template v-if="capabilities.advanced.preorder">
											            <span class="badge badge-warning text-black">
												            <i class="fad fa-info-circle"/>
												            Pre-Order
											            </span>
										            </template>
		                          </span>
								            </div>
							            </div>
							            <div class="row">
								            <div class="col-2 text-center">
									            <i class="fas fa-server"/>
								            </div>
								            <div class="col-8">
			                          <span class="c-feature-description">
			                            <template v-if="capabilities.advanced.servers === -1">
			                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.servers')}}
			                              <span class="small c-feature-description text-muted">
			                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
			                              </span>
			                            </template>
			                            <template v-else-if="capabilities.advanced.servers === 0" class="text-muted">
			                              {{$t('billing.subscription.plans.descriptors.limit_servers')}}
			                            </template>
			                            <template v-else>
			                              {{ $n(capabilities.advanced.servers) }} {{$t('billing.subscription.plans.descriptors.servers')}}
			                            </template>
			                          </span>
								            </div>
							            </div>
							            <div class="row">
								            <div class="col-2 text-center">
									            <i class="far fa-ban"/>
								            </div>
								            <div class="col-8">
			                          <span class="c-feature-description">
			                            <template v-if="capabilities.advanced.banlists === -1">
			                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.banlists')}}
			                              <span class="small c-feature-description text-muted">
			                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
			                              </span>
			                            </template>
			                            <template v-else-if="capabilities.advanced.banlists === 0" class="text-muted">
			                              {{$t('billing.subscription.plans.descriptors.limit_banlists')}}
			                            </template>
			                            <template v-else>
			                              {{ $n(capabilities.advanced.banlists) }} {{$t('billing.subscription.plans.descriptors.banlists')}}
			                            </template>
			                          </span>
								            </div>
							            </div>
							            <div class="row">
								            <div class="col-2 text-center">
									            <i class="fad fa-users"/>
								            </div>
								            <div class="col-8">
			                          <span class="c-feature-description">
			                            <template v-if="capabilities.advanced.teams === -1">
			                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.teams')}}
			                              <span class="small c-feature-description text-muted">
			                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
			                              </span>
			                            </template>
			                            <template v-else-if="capabilities.advanced.teams === 0">
			                              <span class="text-muted">
			                                {{$t('billing.subscription.plans.descriptors.limit_teams')}}
			                              </span>
			                            </template>
			                            <template v-else>
			                              {{ $n(capabilities.advanced.teams) }} {{$t('billing.subscription.plans.descriptors.teams')}}
			                            </template>
			                          </span>
								            </div>
							            </div>

							            <!-- Feature Checks -->
							            <div class="mt-3"/>

							            <div class="row">
								            <div class="col-10 text-left">
			                          <span class="badge badge-info c-feature-description text-black w-100 font-size-16">
			                            {{$t('billing.subscription.plans.descriptors.carryover_community')}}
			                          </span>
								            </div>
							            </div>
						            </div>
					            </div>
					            <div class="col-lg-5 col-sm-12 c-sm">
						            <div class="c-features-horizontal">
							            <div class="row">
						                <div class="col">
							                <div class="row">
								                <div class="col-2 text-center">
									                <i class="far fa-check text-success"/>
								                </div>
								                <div class="col-8">
				                          <span class="c-feature-description">
				                            {{$t('billing.subscription.plans.features.advanced_sla')}}
					                          <small><a href="https://cftools.com/legal/sla-cloud-advanced" target="_blank">{{$t('billing.subscription.plans.features.advanced_sla_terms')}} <i class="fas fa-external-link-alt"/></a></small>
				                          </span>
								                </div>
							                </div>
							                <div class="row">
								                <div class="col-2 text-center">
									                <i class="far fa-check text-success"/>
								                </div>
								                <div class="col-8">
				                          <span class="c-feature-description">
					                          <template v-if="capabilities.advanced.preorder">
						                          {{$t('billing.subscription.plans.features.advanced_badge_early')}}
													            <span class="badge badge-warning text-black">
														            <i class="fad fa-info-circle"/>
														            Pre-Order
													            </span>
												            </template>
					                          <template v-else>
						                          {{$t('billing.subscription.plans.features.advanced_badge')}}
					                          </template>
				                          </span>
								                </div>
							                </div>
							                <!--
							                <div class="row">
								                <div class="col-2 text-center">
									                <i class="far fa-check text-success"/>
								                </div>
								                <div class="col-8">
				                          <span class="c-feature-description">
				                            {{$t('billing.subscription.plans.features.watchers_teams')}}
				                          </span>
								                </div>
							                </div>
							                -->
							                <div class="row">
								                <div class="col-2 text-center">
									                <i class="far fa-check text-success"/>
								                </div>
								                <div class="col-8">
				                          <span class="c-feature-description">
				                            {{$t('billing.subscription.plans.features.privacy_teams')}}
					                          <template v-if="capabilities.advanced.preorder">
													            <span class="badge badge-warning text-black">
														            <i class="fad fa-info-circle"/>
														            Pre-Order
													            </span>
												            </template>
				                          </span>
								                </div>
							                </div>
							                <div class="row">
								                <div class="col-2 text-center">
									                <i class="far fa-check text-success"/>
								                </div>
								                <div class="col-8">
				                          <span class="c-feature-description">
				                            {{$t('billing.subscription.plans.features.dedicated_ip')}}
				                          </span>
								                </div>
							                </div>
							                <div class="row">
								                <div class="col-2 text-center">
									                <i class="far fa-check text-success"/>
								                </div>
								                <div class="col-8">
				                          <span class="c-feature-description">
				                            {{$t('billing.subscription.plans.features.advanced_audit_trail')}}
					                          <template v-if="capabilities.advanced.preorder">
													            <span class="badge badge-warning text-black">
														            <i class="fad fa-info-circle"/>
														            Pre-Order
													            </span>
												            </template>
				                          </span>
								                </div>
							                </div>
							                <div class="row">
								                <div class="col-2 text-center">
									                <i class="far fa-check text-success"/>
								                </div>
								                <div class="col-8">
				                          <span class="c-feature-description">
				                            {{$t('billing.subscription.plans.features.om_support')}}
				                          </span>
								                </div>
							                </div>
						                </div>
							            </div>
						            </div>
					            </div>
				            </div>
			            </div>
		            </div>
	            </div>
              <div class="row mt-4 mb-4">
                <div class="col">
                  <button class="btn btn-info btn-block btn-lg" v-b-modal.subscriptionDetails>
                    <h5 class="text-uppercase text-black mb-0">
                      {{$t('billing.subscription.details.button')}}
                    </h5>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-modal
            id="subscriptionDetails"
            ref="subscriptionDetails"
            size="xl"
            hide-header
            hide-footer
            :scrollable="true"
            content-class="hard-border"
        >
          <div>
            <div>
              <h3 class="d-inline-block">
                Subscription Details
              </h3>
              <button class="btn btn-sm btn-outline-danger float-right d-inline-block" @click="$bvModal.hide('subscriptionDetails')">
                {{$t('billing.subscription.details.comparison.close')}}
              </button>
              <hr>
              <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-12">
                  <h4>
                    {{$t('billing.subscription.details.availability.title')}}
                  </h4>
                  <p>
                    {{$t('billing.subscription.details.availability.message')}}
                  </p>
                  <p>
                    {{$t('billing.subscription.details.availability.link')}}
                    <a href="https://help.cftools.com/cf-tools-cloud/feature-availability" target="_blank">https://help.cftools.com/cf-tools-cloud/feature-availability</a>
                  </p>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <h4>
                    {{$t('billing.subscription.details.game_integration.title')}}
                  </h4>
                  <p>
                    {{$t('billing.subscription.details.game_integration.message')}}
                  </p>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <h4>
                    {{$t('billing.subscription.details.fair_use.title')}}
                  </h4>
                  <p>
                    {{$t('billing.subscription.details.fair_use.message')}}
                  </p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col">
                  <h4>{{$t('billing.subscription.details.comparison.title')}}</h4>
                  <div class="table-responsive">
                    <table class="table table-dark mb-0">
                      <thead class="mb-0 h5">
                        <tr>
                          <th>Feature</th>
                          <th>Free</th>
                          <th>Basic</th>
                          <th>Pro</th>
                          <th>Community</th>
	                        <th>Advanced</th>
                        </tr>
                      </thead>
                      <tbody class="mb-0 h6">
                        <tr>
                          <td>Ticket Support</td>
                          <td>
                            <i class="far fa-check text-warning font-size-20"/> <span class="ml-2 badge badge-warning text-black">Limited</span>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/> <span class="ml-2 badge badge-info text-black">Priority</span>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/> <span class="ml-2 badge badge-info text-black">Priority</span>
	                        </td>
                        </tr>
                        <tr>
                          <td>24/7 RCon</td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Servers</td>
                          <td>
                            1
                          </td>
                          <td>
                            2
                          </td>
                          <td>
                            5
                          </td>
                          <td>
                            Unlimited, Fair-Use
                          </td>
	                        <td>
		                        Unlimited, Fair-Use
	                        </td>
                        </tr>
                        <tr>
                          <td>Ban Lists</td>
                          <td>
                            1
                          </td>
                          <td>
                            1
                          </td>
                          <td>
                            2
                          </td>
                          <td>
                            Unlimited, Fair-Use
                          </td>
	                        <td>
		                        Unlimited, Fair-Use
	                        </td>
                        </tr>
                        <tr>
                          <td>Teams & Permissions</td>
                          <td>
                            <i class="far fa-times text-danger font-size-20"/>
                          </td>
                          <td>
                            1
                          </td>
                          <td>
                            2
                          </td>
                          <td>
                            Unlimited, Fair-Use
                          </td>
	                        <td>
		                        Unlimited, Fair-Use
	                        </td>
                        </tr>
                        <tr>
                          <td>Player Profiles</td>
                          <td>
	                          <span class="text-warning">Basic Details</span>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Audit Log</td>
                          <td>
                            2 Day Retention
                          </td>
                          <td>
                            3 Months Retention
                          </td>
                          <td>
	                          6 Months Retention
                          </td>
                          <td>
                            12 Months Retention
                          </td>
	                        <td>
		                        12 Months Retention & Route level trail
	                        </td>
                        </tr>
                        <tr>
                          <td>Server Log</td>
                          <td>
                            2 Day Retention
                          </td>
                          <td>
                            30 Day Retention
                          </td>
                          <td>
                            30 Day Retention
                          </td>
                          <td>
                            90 Day Retention
                          </td>
	                        <td>
		                        90 Day Retention
	                        </td>
                        </tr>
                        <tr>
                          <td>RCon Protocol</td>
                          <td>
                            1 Day Retention
                          </td>
                          <td>
                            7 Day Retention
                          </td>
                          <td>
                            30 Day Retention
                          </td>
                          <td>
                            30 Day Retention
                          </td>
	                        <td>
		                        30 Day Retention
	                        </td>
                        </tr>
                        <tr>
                          <td>Whitelist</td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Chat & Name Filters</td>
                          <td>
                            Limited dynamically
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>In-Game Commands</td>
                          <td>
                            Limited to 4 Commands
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Default Message Localization</td>
                          <td>
                            <i class="far fa-times text-danger font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Scheduler</td>
                          <td>
                            Limited to 1 Job
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Messenger</td>
                          <td>
                            Limited to 2 Messages
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Triggers</td>
                          <td>
                            Limited to 1 Triggers
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Integrations</td>
                          <td>
                            Limited to 1 WebHook Integration
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>
                            Queue Priority
                            <div>
                              <small class="font-size-12">
                                Availability:
                                <ul style="list-style: none;" class="m-0 p-0">
                                  <li>
                                    <span class="text-code">
                                      <img :src="'/' +gameIcon(Game.DAYZ)" alt="dz logo" class="logo-img" height="16px" width="16px">
                                      OmegaManager or FTP (Beta)
                                    </span>
                                  </li>
                                </ul>
                              </small>
                            </div>
                          </td>
                          <td>
                            <i class="far fa-times text-danger font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Reserved Slots</td>
                          <td>
                            <i class="far fa-times text-danger font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Game-Integration</td>
                          <td colspan="4" class="text-center">
                            Access <a href="https://help.cftools.com/cf-tools-cloud/availability" target="_blank">https://help.cftools.com/cf-tools-cloud/availability</a> for details
                          </td>
                        </tr>
                        <tr>
                          <td>VPN Protection</td>
                          <td>
                            <i class="far fa-times text-danger font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Additional purchasable Add-Ons</td>
                          <td>
                            <i class="far fa-times text-danger font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>API Access <small><a href="https://help.cftools.com/api-1/data-api" target="_blank">More info</a></small></td>
                          <td>
                            <i class="far fa-check text-warning font-size-20"/> <span class="ml-2 badge badge-warning text-black">Limited</span>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>
                            Geo-Regional Hosting
                            <div>
                              <small class="font-size-12">
                                Available Regions:
                                <ul style="list-style: none;" class="m-0 p-0">
                                  <li>
                                    <span class="text-code">
                                      <span title="eu-central" class="flag-icon flag-icon-eu"></span>
                                      eu-central
                                    </span>
                                  </li>
                                  <!--
                                  <li>
                                    <span class="text-code">
                                      <span title="eu-russia" class="flag-icon flag-icon-ru"></span>
                                      eu-russia
                                    </span>
                                  </li>
                                  -->
                                  <li>
                                    <span class="text-code">
                                      <span title="na-canada" class="flag-icon flag-icon-ca"></span>
                                      na-canada
                                    </span>
                                  </li>
                                  <li>
                                    <span class="text-code">
                                      <span title="na-usa-east" class="flag-icon flag-icon-us"></span>
                                      na-usa-east
                                    </span>
                                  </li>
                                  <li>
                                    <span class="text-code">
                                      <span title="as-singapore" class="flag-icon flag-icon-sg"></span>
                                      as-singapore
                                    </span>
                                  </li>
                                  <li>
                                    <span class="text-code">
                                      <span title="oc-australia" class="flag-icon flag-icon-au"></span>
                                      oc-australia
                                    </span>
                                  </li>
                                </ul>
                              </small>
                            </div>
                          </td>
                          <td>
                            <i class="far fa-times text-danger font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/>
	                        </td>
                        </tr>
                        <tr>
                          <td>Privacy Mode</td>
                          <td>
                            <i class="far fa-times text-danger font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-times text-danger font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/> <!-- <span class="badge badge-info text-black">Including Team Access</span> -->
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/> <span class="badge badge-info text-black">Including Team Access</span>
	                        </td>
                        </tr>
                        <tr>
                          <td>Watch Profiles</td>
                          <td>
                            <i class="far fa-times text-danger font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-times text-danger font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/>
                          </td>
                          <td>
                            <i class="far fa-check text-success font-size-20"/> <!-- <span class="badge badge-info text-black">Including Team Access</span> -->
                          </td>
	                        <td>
		                        <i class="far fa-check text-success font-size-20"/> <span class="badge badge-info text-black">Including Team Access</span>
	                        </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <button class="btn btn-sm btn-outline-danger btn-block" @click="$bvModal.hide('subscriptionDetails')">
                    {{$t('billing.subscription.details.comparison.close')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </template>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="margin: auto; width: 80px;">
        <half-circle-spinner
            :animation-duration="2000"
            :size="80"
            color="white"
            class="align-middle"
        />
      </div>
    </template>
  </div>
</template>
